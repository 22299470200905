import React, { useCallback, useMemo } from 'react';
import { Field, Formik, Form, FormikHelpers } from 'formik';
import styled from '@emotion/styled';
import { IAgreements } from '../../../models/onboarding.models';
import { Typography, Link, CheckboxWithLabel, Button, IconButton } from '../../../../theme/components';
import { DownloadIcon } from '../../../../theme/icons';
import FormLayout, {
  Content,
  Fieldset,
  FormFieldsWrapper,
  ButtonsContainer,
  ErrorMessage,
} from '../../../../common/components/FormLayout';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../Store';

const AgreementLayout = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 44px;
  a.MuiIconButton-root {
    margin-right: -12px;
  }
`;

type AgreementsLabelMapper = {
  [key: string]: string;
};

export const AgreementsStep: React.FC<{ onNext: (dataToSubmit: any) => void }> = ({ onNext }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();
  const {
    loading: isLoading,
    currentStep: { payload },
  } = useSelector((state: IRootState) => state.onboarding);
  const agreements = useMemo(() => {
    const { agreements } = (payload as undefined | { agreements?: IAgreements[] }) || { agreements: [] };
    return agreements || [];
  }, [payload]);

  const agreementsLabelMap: AgreementsLabelMapper = {
    RISK_AND_WARNINGS_DISCLAIMER: t('agreements_risk_disclosure'),
    PRIVACY_NOTICE: t('agreements_privacy_policy'),
  };

  const handleSubmit = useCallback(
    (values: { [k: string]: boolean }, { setErrors, setSubmitting }: FormikHelpers<{ [k: string]: boolean }>) => {
      const valid = agreements.every(({ type }) => values[type]);
      if (valid) {
        const formData = {
          agreements: [
            { ...agreements[0], accepted: true },
            { ...agreements[1], accepted: true },
          ],
        };
        onNext(formData);
      } else {
        setErrors({ error: t('agreements_error') });
        setSubmitting(false);
      }
    },
    [],
  );

  return (
    <FormLayout>
      <Typography variant="h2" gutterBottom={true}>
        {t('agreements_title')}
      </Typography>
      <Content>
        <Typography gutterBottom={true}>{t('agreements_descriptive_text')}</Typography>
        <Formik initialValues={Object.fromEntries(agreements.map((d) => [d.type, false]))} onSubmit={handleSubmit}>
          {({ isValid, errors }) => {
            return (
              <Form>
                <FormFieldsWrapper marginTop={3} marginBottom={3}>
                  <Fieldset>
                    {agreements.map((d: IAgreements, i) => {
                      return (
                        <AgreementLayout key={`${d.type}-${i}`}>
                          <Field
                            type="checkbox"
                            component={CheckboxWithLabel}
                            label={agreementsLabelMap[d.type]}
                            name={d.type}
                          />
                          {isMobile ? (
                            <IconButton component="a" href={d.url} target="_blank" rel="noreferrer">
                              <DownloadIcon />
                            </IconButton>
                          ) : (
                            <Link href={d.url} target="_blank" rel="noreferrer">
                              {t('agreements_read')}
                            </Link>
                          )}
                        </AgreementLayout>
                      );
                    })}
                    {!isValid && <ErrorMessage>{errors.error}</ErrorMessage>}
                  </Fieldset>
                </FormFieldsWrapper>
                <ButtonsContainer alignCenter>
                  <Button type="submit" loading={isLoading} fullWidth={isMobile} id="web-onboarding-accept-agreements">
                    {t('common_continue')}
                  </Button>
                </ButtonsContainer>
              </Form>
            );
          }}
        </Formik>
      </Content>
    </FormLayout>
  );
};
