import { OnboardingSteps } from '../models/onboarding.models';

// key/value map object used for mapping of _links href to the relevant onboarding step
type LinkToStepMapper = {
  [key: string]: OnboardingSteps;
};

const LinkToStepMapper: LinkToStepMapper = {
  'applications/current/agreements': OnboardingSteps.AGREEMENTS,
  'applications/current/personal_details/email-verification': OnboardingSteps.EMAIL,
  'applications/current/personal_details': OnboardingSteps.FULLNAME,
  'applications/current/addresses': OnboardingSteps.ADDRESS,
  'applications/current/proof_of_id': OnboardingSteps.PROOF_OF_ID,
  'applications/current/tax_number': OnboardingSteps.TAX_NUMBER,
  'applications/current/transaction_reporting': OnboardingSteps.TRANSACTION_REPORTING,
  'applications/current/financial_details': OnboardingSteps.FINANCE_DETAILS,
  'applications/current/live_selfie': OnboardingSteps.SELFIE,
  'applications/current/proof_of_address': OnboardingSteps.ADDRESS_VERIFICATION,
  'applications/current/personal_details/phone_number': OnboardingSteps.PHONE_NUMBER,
  'applications/current/phone_verification': OnboardingSteps.PHONE_VERIFICATION,
  'applications/current/signed_agreements': OnboardingSteps.SIGNED_AGREEMENTS,
};

export default LinkToStepMapper;
